import useSafeState from '../../utils/safe-state';
import React, {useEffect, useState, useRef} from "react";
import {Form, Row, Col, Input, Button, message, Select, List, Card, Spin, Modal, Checkbox, CheckboxProps} from 'antd';
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import { AxiosResponse, AxiosError } from 'axios';

import {antdContext} from "../../utils/antdContext";
import { openNotification } from '../../utils/openNotification';
import {SelectType} from "../../interfaces/SelectType";


interface Interface{
    locationId?: any;
    mode?: "View" | "AddNew" | "Edit";
}

const NewLocationForm = (props : Interface) => {

    

    const [form] = Form.useForm();
    
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");

    const [loading, setLoading] = useSafeState(false);

    const [canContinue, setCanContinue] = useSafeState<boolean>();
    const [isView, setIsView] = useSafeState<boolean>();
    const [isEdit, setIsEdit] = useSafeState<boolean>();
    const [isNewUser, setIsNewUser] = useSafeState<boolean>(true);

    const [loginName, setLoginName] = useSafeState<string>();
    const [userRoles, setUserRoles] = useSafeState<Array<string>>([]);
    const [allRoles, setAllRoles] = useSafeState<any>();
    const [deleteItem, setDeleteItem] = useSafeState<any>();
    const [isModalVisible, setIsModalVisible] = useSafeState(false);


    const viewMode = () => {
        if(axiosInstance.current != null) {
            
            axiosInstance.current.get("/public/user/get-user-data?userName=" + loginName, {})
                .then(function (response : AxiosResponse<any>)
                {
                    console.log(response.data);
                    let user = JSON.parse(JSON.stringify(response.data));
                    
                    form.setFieldsValue({"loginname": user.loginName});
                    form.setFieldsValue({"firstname": user.firstName});
                    form.setFieldsValue({"lastname": user.lastName});
                    setCanContinue(user.canContinueAfterError);

                }).catch(function (error :any) {
                    message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                    console.log(error)
                    setLoading(false);
            });

            
        }
    }

    const getAllRoles = () => {

        if(axiosInstance.current != null) {
            
            const resp = axiosInstance.current.get("/resource/roles/get-all-roles", {
                }
                ).then(function (response : AxiosResponse<any>)
                {
                    let tmpArr: Array<SelectType> = [];
                    response.data.forEach((key:any) => {
                        tmpArr.push(
                            {
                                label: key,
                                value: key
                            })
                        })
                    setAllRoles(tmpArr);
                    

                }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });

            
        }

    }

    const getUserRoles = () => {

        if(axiosInstance.current != null) {
            
            const resp = axiosInstance.current.get("/resource/userrole/get-users-roles?userName=" + loginName, {
                }
                ).then(function (response : AxiosResponse<any>)
                {
                    setUserRoles(response.data);
                    

                }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });

            
        }

    }

    useEffect(() => {
        getAllRoles();
        let params:any = JSON.parse(JSON.stringify(props));
        
        setLoginName(params.data.loginName);
        let initMode:any = params.data == undefined ? undefined : params.data.mode;
        
        setIsView(initMode == 'View' ? true : false);
        setIsEdit(initMode == 'Edit' ? true : false);
        
    }, []);


    useEffect(() => {
        
        if (isView || isEdit) {
            setIsNewUser(false);
            getUserRoles();
            viewMode();
        }
    },[isView,isEdit]

    );

    const handleFinish = () => {
        

        if(axiosInstance.current != null) {
            setLoading(true);                    
                    

            let password1:any = form.getFieldValue(["password1"]);
            let password2:any = form.getFieldValue(["password2"]);
            let password:any;

            if (password1 == password2) {
                password = password1;
            } else {
                setLoading(false);
                openNotification('error',"passwords do not match");
                return;
            }

            axiosInstance.current.put("/resource/user/add-update-user",
                {
                    loginName: form.getFieldValue(["loginname"]),
                    firstName: form.getFieldValue(["firstname"]),
                    lastName: form.getFieldValue(["lastname"]),
                    password: password,
                    roles: userRoles,
                    canContinueAfterError: canContinue
                }
                ).then(function (response : AxiosResponse<any>)
                {      
                    setLoading(false);
                    openNotification('success','');
                
                }).catch(function (error :AxiosError) {
                    let errResp = JSON.parse(JSON.stringify(error.response));
                    let errMessage = errResp.data.message;
                    openNotification('error',errMessage);
                setLoading(false);
            });

        }
    }

    const handleOk = () => {
        setIsModalVisible(false);
        const newUserRoles = userRoles.filter(listItem => listItem !== deleteItem);
        setUserRoles(newUserRoles);
    }

    const handleCancel = () => {
        setIsModalVisible(false);  
    }

    const handleUserRolesListClick = (item:any) => {
        setDeleteItem(item);
        setIsModalVisible(true);
    }

    const handleAllRolesListClick = (item:any) => {
        if (userRoles.indexOf(item) == -1) {
            const newUserRoles = [...userRoles, item];
            setUserRoles(newUserRoles);
        }
    }

    const onChangeCanContinue: CheckboxProps['onChange'] = (e) => {
        setCanContinue(e.target.checked);
    };


    return(
        
                <Form id="newuser"  layout='vertical' form={form}  onFinish={() => handleFinish()}>
                <Spin spinning={loading}></Spin>    
                <Row gutter={24}>
                    <Col span={14}>
                        <Form.Item
                            name="loginname"
                            label={<Trans>Login Name</Trans>}
                            children={<Input disabled={isView}/>}
                            rules={[{
                                type:"string",
                                required: true,
                                message: <Trans>Please enter the login name</Trans>
                            }]}
                        />
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="firstname"
                            label={<Trans>First Name</Trans>}
                            children={<Input disabled={isView}/>}
                        />
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="lastname"
                            label={<Trans>Last Name</Trans>}
                            children={<Input disabled={isView}/>}
                        />
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="password1"
                            label={<Trans>Password</Trans>}
                            children={<Input.Password disabled={isView}/>}
                            rules={[{
                                type:"string",
                                required: isNewUser,
                                message: <Trans>Please enter the password</Trans>
                            }]}
                        />
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="password2"
                            label={<Trans>Password again</Trans>}
                            children={<Input.Password disabled={isView}/>}
                            rules={[{
                                type:"string",
                                required: isNewUser,
                                message: <Trans>Please enter the password</Trans>
                            }]}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="allroles"
                            label={<Trans>Roles</Trans>}
                            children={
                                <Select
                                    disabled={isView}
                                    notFoundContent={<Trans id={'No data'}/>}
                                    style={{ width: '100%' }}
                                    placeholder={<Trans id={'Roles'}></Trans>}
                                    options={allRoles}
                                    onChange={
                                        (item) => handleAllRolesListClick(item)
                                    }

                                />
                            }
                                    
                        />
                    </Col>
                    <Col span={12}>
                        <List
                            size="small"
                            header={<div>{<Trans>User Roles</Trans>}</div>}
                            bordered
                            dataSource={userRoles}
                            renderItem={
                                (item) => <List.Item
                                    key={item}
                                    onClick={() => handleUserRolesListClick(item)}
                                    className='listhover'>
                                    {item}
                                </List.Item>
                            }

                        />
                    </Col>
                </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="canContinueAfterError"
                                children={
                                    <Checkbox onChange={onChangeCanContinue} checked={canContinue}><Trans>Can Continue</Trans></Checkbox>
                                }

                            />
                        </Col>
                    </Row>
                <Row>
                    <Modal title={<Trans>Are you sure want to delete this role</Trans>} visible={isModalVisible} onOk={handleOk}
                        onCancel={handleCancel} width={230}>
                            <span> {deleteItem} </span>

                    </Modal>
                </Row>
                <Form.Item
                    children={
                        <>
                            <Button htmlType="button" type="primary" onClick={() => form.submit()}>
                                <Trans>Save</Trans>
                            </Button>
                        </>
                    }
                />
            </Form>
    );

}
export default NewLocationForm;
